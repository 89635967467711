import React from "react";

const Navigation = () => {
  return (
    <nav className="navigation">
      <h1 className="navigation-logo">Flores Penuh Kasih</h1>
    </nav>
  );
};

export default Navigation;
